(function(i,l){typeof exports=="object"&&typeof module<"u"?module.exports=l(require("marked")):typeof define=="function"&&define.amd?define(["marked"],l):(i=typeof globalThis<"u"?globalThis:i||self,i.markedPlaintify=l(i.marked))})(this,function(i){"use strict";function l(d={}){const t={},f=["constructor","hr","checkbox","br"],a=["strong","em","codespan","del","text"],m=["html","code","codespan"];let r=[];return Object.getOwnPropertyNames(i.Renderer.prototype).forEach(e=>{f.includes(e)?t[e]=()=>"":a.includes(e)?t[e]=n=>n:m.includes(e)?t[e]=n=>u(n)+`

`:e==="list"?t[e]=n=>`
`+n.trim()+`

`:e==="listitem"?t[e]=n=>`
`+n.trim():e==="blockquote"?t[e]=n=>n.trim()+`

`:e==="table"?t[e]=(n,s)=>(r=[],s):e==="tablerow"?t[e]=n=>{const s=n.split("__CELL_PAD__").filter(Boolean);return r.map((c,o)=>c+": "+s[o]).join(`
`)+`

`}:e==="tablecell"?t[e]=(n,s)=>(s.header&&r.push(n),n+"__CELL_PAD__"):e==="link"||e==="image"?t[e]=(n,s,c)=>c||"":t[e]=n=>n+`

`}),{renderer:{...t,...d}}}function u(d){const t={"&":"&amp;","<":"&lt;",">":"&gt;",'"':"&quot;","'":"&#39;"};return d.replace(/[&<>"']/g,f=>t[f])}return l});
